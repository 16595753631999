<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="83.467"
    height="36.536"
    viewBox="0 0 83.467 36.536"
  >
    <title>Ambulance</title>
    <g
      id="Group_267"
      data-name="Group 267"
      transform="translate(-38.031 -160.498)"
    >
      <g
        id="Group_266"
        data-name="Group 266"
        transform="translate(38.031 160.498)"
      >
        <g
          id="Group_265"
          data-name="Group 265"
          transform="translate(0 0)"
        >
          <g
            id="Group_253"
            data-name="Group 253"
          >
            <path
              id="Rectangle_26"
              data-name="Rectangle 26"
              d="M0,0H74.855a0,0,0,0,1,0,0V0A10.042,10.042,0,0,1,64.813,10.042H10.042A10.042,10.042,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
              transform="translate(1.539 23.314)"
              fill="#201c20"
            />
            <rect
              id="Rectangle_27"
              data-name="Rectangle 27"
              width="0.703"
              height="3.993"
              transform="translate(29.471 2.878)"
              fill="#363538"
            />
            <path
              id="Path_221"
              data-name="Path 221"
              d="M172.737,178.763v2.4l3.18.3v-3Z"
              transform="translate(-146.447 -174.96)"
              fill="#0072c8"
            />
            <path
              id="Path_222"
              data-name="Path 222"
              d="M173.44,180.313v-1.331l2.477-.23v-.285l-3.18.3v2.4l.7.065Z"
              transform="translate(-146.447 -174.96)"
              fill="#008bc9"
            />
            <path
              id="Path_223"
              data-name="Path 223"
              d="M64.153,199.682a5.143,5.143,0,0,0-3.832,1.713l-5,5.584a5.865,5.865,0,0,1-2.454,1.632l-9.191,3.177a2.006,2.006,0,0,0-1.351,1.9v8.53h1.631a6.787,6.787,0,0,1,13.465,0H71.662V199.682Z"
              transform="translate(-41.489 -192.034)"
              fill="#fade01"
            />
            <path
              id="Path_224"
              data-name="Path 224"
              d="M38.031,296.12v2.65a1.06,1.06,0,0,0,1.06,1.06H40.5a6.764,6.764,0,0,1,1.72-3.71Z"
              transform="translate(-38.031 -269.65)"
              fill="#363538"
            />
            <path
              id="Path_225"
              data-name="Path 225"
              d="M192.631,160.5v32.323h22.9a5.84,5.84,0,1,1,11.387,0h14.422V160.5Z"
              transform="translate(-162.458 -160.498)"
              fill="#fade01"
            />
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="10.348"
              height="5.007"
              transform="translate(68.533 24.436)"
              fill="#006f23"
            />
            <rect
              id="Rectangle_29"
              data-name="Rectangle 29"
              width="10.348"
              height="5.007"
              transform="translate(57.949 19.772)"
              fill="#006f23"
            />
            <rect
              id="Rectangle_30"
              data-name="Rectangle 30"
              width="10.348"
              height="5.007"
              transform="translate(47.95 24.718)"
              fill="#006f23"
            />
            <rect
              id="Rectangle_31"
              data-name="Rectangle 31"
              width="4.585"
              height="1.775"
              transform="translate(78.881 29.687)"
              fill="#363538"
            />
            <path
              id="Path_226"
              data-name="Path 226"
              d="M43.7,277.656H42.327v-3.513h1.816a.83.83,0,0,1,.807,1.023l-.442,1.852A.83.83,0,0,1,43.7,277.656Z"
              transform="translate(-41.489 -251.963)"
              fill="#fedf03"
            />
            <g
              id="Group_250"
              data-name="Group 250"
              transform="translate(25.49 19.333)"
            >
              <rect
                id="Rectangle_32"
                data-name="Rectangle 32"
                width="2.317"
                height="3.846"
                rx="1.159"
                fill="#363538"
              />
              <path
                id="Path_227"
                data-name="Path 227"
                d="M175.249,261.806h-.618v2.967h.618a.089.089,0,0,0,.089-.089v-2.789A.089.089,0,0,0,175.249,261.806Z"
                transform="translate(-173.46 -261.367)"
                fill="#1d1d1b"
              />
            </g>
            <rect
              id="Rectangle_33"
              data-name="Rectangle 33"
              width="0.641"
              height="4.487"
              transform="translate(78.881 22.739)"
              fill="#de0025"
            />
            <rect
              id="Rectangle_34"
              data-name="Rectangle 34"
              width="0.641"
              height="2.745"
              transform="translate(78.881 2.761)"
              fill="#de0025"
            />
            <path
              id="Path_228"
              data-name="Path 228"
              d="M192.845,165.83l.031.39h48.594l.042-.39"
              transform="translate(-162.63 -164.789)"
              opacity="0.5"
            />
            <rect
              id="Rectangle_35"
              data-name="Rectangle 35"
              width="11.791"
              height="1.282"
              transform="translate(33.7 30.18)"
              fill="#babcbe"
            />
            <path
              id="Path_229"
              data-name="Path 229"
              d="M130.987,218.379V212.05a1.111,1.111,0,0,0-1.111-1.111h-4.2a2.95,2.95,0,0,0-2.2.981l-4.449,4.97a1.033,1.033,0,0,0-.263.689v.8a1.111,1.111,0,0,0,1.111,1.111h10A1.111,1.111,0,0,0,130.987,218.379Z"
              transform="translate(-103.01 -201.094)"
              fill="#1a161a"
            />
            <path
              id="Path_230"
              data-name="Path 230"
              d="M136.983,221.322v-5.817a1,1,0,0,0-.979-1.021h-3.7a2.552,2.552,0,0,0-1.934.9l-3.919,4.568a.973.973,0,0,0-.232.633v.734a1,1,0,0,0,.979,1.021H136A1,1,0,0,0,136.983,221.322Z"
              transform="translate(-109.006 -203.947)"
              fill="#262227"
            />
            <path
              id="Path_231"
              data-name="Path 231"
              d="M77.95,268.653H76.307a.572.572,0,0,1-.572-.572h0a.572.572,0,0,1,.572-.572H77.95a.572.572,0,0,1,.572.572h0A.572.572,0,0,1,77.95,268.653Z"
              transform="translate(-68.376 -246.624)"
              fill="#e64718"
            />
            <path
              id="Path_232"
              data-name="Path 232"
              d="M133.78,236.677a10.277,10.277,0,0,0-.04,1.825l-1.169,1.419h3.86c-.609-1.386-1.508-3.238-2.187-3.577C134.147,236.3,133.818,236.466,133.78,236.677Z"
              transform="translate(-114.119 -221.535)"
            />
            <g
              id="Group_251"
              data-name="Group 251"
              transform="translate(20.824 9.722)"
            >
              <path
                id="Path_233"
                data-name="Path 233"
                d="M149.343,220.96a.11.11,0,0,1-.078-.188l2.485-2.485a.11.11,0,0,1,.155.155l-2.485,2.485A.11.11,0,0,1,149.343,220.96Z"
                transform="translate(-148.355 -216.705)"
                fill="#363538"
              />
              <path
                id="Path_234"
                data-name="Path 234"
                d="M154.186,235.01a.11.11,0,0,1-.078-.188l3.882-3.882a.11.11,0,0,1,.155.155l-3.882,3.882A.11.11,0,0,1,154.186,235.01Z"
                transform="translate(-152.252 -226.889)"
                fill="#363538"
              />
              <path
                id="Path_235"
                data-name="Path 235"
                d="M388.721,214.413a.11.11,0,0,1-.078-.188l3.882-3.882a.11.11,0,0,1,.156.155L388.8,214.38A.11.11,0,0,1,388.721,214.413Z"
                transform="translate(-341.012 -210.311)"
                fill="#363538"
              />
              <path
                id="Path_236"
                data-name="Path 236"
                d="M144.839,237.38a.11.11,0,0,1-.076-.189l2.513-2.445a.11.11,0,1,1,.153.158l-2.513,2.445A.11.11,0,0,1,144.839,237.38Z"
                transform="translate(-144.729 -229.953)"
                fill="#363538"
              />
            </g>
            <rect
              id="Rectangle_36"
              data-name="Rectangle 36"
              width="10.348"
              height="5.007"
              transform="translate(37.496 19.916)"
              fill="#006f23"
            />
            <rect
              id="Rectangle_37"
              data-name="Rectangle 37"
              width="7.542"
              height="5.007"
              transform="translate(30.173 24.722)"
              fill="#006f23"
            />
            <rect
              id="Rectangle_38"
              data-name="Rectangle 38"
              width="7.542"
              height="5.007"
              transform="translate(22.52 24.722)"
              fill="#006f23"
            />
            <g
              id="Group_252"
              data-name="Group 252"
              transform="translate(33.505 5.254)"
            >
              <rect
                id="Rectangle_39"
                data-name="Rectangle 39"
                width="11.791"
                height="25.817"
                transform="translate(0.39 0.39)"
                fill="#fade01"
              />
              <path
                id="Path_237"
                data-name="Path 237"
                d="M222.272,214.017H209.7v-26.6h12.571Zm-11.791-.781h11.01V188.2h-11.01Z"
                transform="translate(-209.701 -187.42)"
                fill="#fffff1"
              />
            </g>
            <rect
              id="Rectangle_40"
              data-name="Rectangle 40"
              width="11.198"
              height="5.007"
              transform="translate(34.216 19.968)"
              fill="#006f23"
            />
            <rect
              id="Rectangle_41"
              data-name="Rectangle 41"
              width="10.67"
              height="5.007"
              transform="translate(12.14 19.968)"
              fill="#006f23"
            />
            <path
              id="Path_238"
              data-name="Path 238"
              d="M123.278,225.341H112.537a.94.94,0,0,1-.9-.634,7.758,7.758,0,0,0-2.41-3.5.946.946,0,0,1-.343-.73v-7.5a.951.951,0,0,1,.243-.635l5.507-6.152a4.146,4.146,0,0,1,3.086-1.379h5.555a.954.954,0,0,1,.953.952v18.625A.954.954,0,0,1,123.278,225.341Zm-5.555-20.335a3.952,3.952,0,0,0-2.941,1.314l-5.507,6.152a.756.756,0,0,0-.193.505v7.5a.751.751,0,0,0,.273.58,7.952,7.952,0,0,1,2.47,3.589.746.746,0,0,0,.712.5h10.741a.758.758,0,0,0,.757-.757V205.763a.758.758,0,0,0-.757-.757Z"
              transform="translate(-95.058 -196.162)"
              fill="#23122f"
              opacity="0.2"
            />
          </g>
          <g
            id="Group_264"
            data-name="Group 264"
            transform="translate(3.706 25.544)"
          >
            <g
              id="Group_258"
              data-name="Group 258"
            >
              <g
                id="Group_257"
                data-name="Group 257"
              >
                <g
                  id="Group_256"
                  data-name="Group 256"
                >
                  <circle
                    id="Ellipse_19"
                    data-name="Ellipse 19"
                    cx="5.496"
                    cy="5.496"
                    r="5.496"
                    fill="#363538"
                  />
                  <g
                    id="Group_255"
                    data-name="Group 255"
                    transform="translate(1.678 1.678)"
                  >
                    <circle
                      id="Ellipse_20"
                      data-name="Ellipse 20"
                      cx="3.818"
                      cy="3.818"
                      r="3.818"
                      fill="#898b8e"
                    />
                    <circle
                      id="Ellipse_21"
                      data-name="Ellipse 21"
                      cx="2.938"
                      cy="2.938"
                      r="2.938"
                      transform="translate(0.28 5.997) rotate(-76.63)"
                      fill="#5c5c5f"
                    />
                    <circle
                      id="Ellipse_22"
                      data-name="Ellipse 22"
                      cx="2.407"
                      cy="2.407"
                      r="2.407"
                      transform="matrix(0.231, -0.973, 0.973, 0.231, 0.921, 5.604)"
                      fill="#48484b"
                    />
                    <circle
                      id="Ellipse_23"
                      data-name="Ellipse 23"
                      cx="1.284"
                      cy="1.284"
                      r="1.284"
                      transform="translate(2.535 2.535)"
                      fill="#898b8e"
                    />
                    <g
                      id="Group_254"
                      data-name="Group 254"
                      transform="translate(1.616 1.619)"
                    >
                      <path
                        id="Path_239"
                        data-name="Path 239"
                        d="M83.339,308.629a.359.359,0,1,1,.358.361A.359.359,0,0,1,83.339,308.629Z"
                        transform="translate(-81.496 -308.271)"
                        fill="#363538"
                      />
                      <path
                        id="Path_240"
                        data-name="Path 240"
                        d="M83.339,327.518a.359.359,0,1,1,.358.361A.359.359,0,0,1,83.339,327.518Z"
                        transform="translate(-81.496 -323.473)"
                        fill="#363538"
                      />
                      <path
                        id="Path_241"
                        data-name="Path 241"
                        d="M76.765,311.65a.359.359,0,1,1,.508,0A.359.359,0,0,1,76.765,311.65Z"
                        transform="translate(-76.121 -310.498)"
                        fill="#363538"
                      />
                      <path
                        id="Path_242"
                        data-name="Path 242"
                        d="M90.122,325.006a.359.359,0,1,1,.508,0A.359.359,0,0,1,90.122,325.006Z"
                        transform="translate(-86.871 -321.247)"
                        fill="#363538"
                      />
                      <path
                        id="Path_243"
                        data-name="Path 243"
                        d="M74.252,318.434a.359.359,0,1,1,.361-.357A.359.359,0,0,1,74.252,318.434Z"
                        transform="translate(-73.895 -315.873)"
                        fill="#363538"
                      />
                      <path
                        id="Path_244"
                        data-name="Path 244"
                        d="M93.141,318.434a.359.359,0,1,1,.361-.357A.359.359,0,0,1,93.141,318.434Z"
                        transform="translate(-89.097 -315.873)"
                        fill="#363538"
                      />
                      <path
                        id="Path_245"
                        data-name="Path 245"
                        d="M77.273,325.009a.359.359,0,1,1,0-.508A.36.36,0,0,1,77.273,325.009Z"
                        transform="translate(-76.121 -321.247)"
                        fill="#363538"
                      />
                      <circle
                        id="Ellipse_24"
                        data-name="Ellipse 24"
                        cx="0.359"
                        cy="0.359"
                        r="0.359"
                        transform="translate(3.147 0.54)"
                        fill="#363538"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g
              id="Group_263"
              data-name="Group 263"
              transform="translate(49.613)"
            >
              <g
                id="Group_262"
                data-name="Group 262"
              >
                <g
                  id="Group_261"
                  data-name="Group 261"
                >
                  <circle
                    id="Ellipse_25"
                    data-name="Ellipse 25"
                    cx="5.496"
                    cy="5.496"
                    r="5.496"
                    fill="#363538"
                  />
                  <g
                    id="Group_260"
                    data-name="Group 260"
                    transform="translate(0.517 0.517)"
                  >
                    <circle
                      id="Ellipse_26"
                      data-name="Ellipse 26"
                      cx="3.818"
                      cy="3.818"
                      r="3.818"
                      transform="translate(0 2.888) rotate(-22.22)"
                      fill="#898b8e"
                    />
                    <circle
                      id="Ellipse_27"
                      data-name="Ellipse 27"
                      cx="2.938"
                      cy="2.938"
                      r="2.938"
                      transform="translate(0.823 4.978) rotate(-45)"
                      fill="#5c5c5f"
                    />
                    <circle
                      id="Ellipse_28"
                      data-name="Ellipse 28"
                      cx="2.407"
                      cy="2.407"
                      r="2.407"
                      transform="translate(1.838 3.666) rotate(-22.323)"
                      fill="#48484b"
                    />
                    <circle
                      id="Ellipse_29"
                      data-name="Ellipse 29"
                      cx="1.284"
                      cy="1.284"
                      r="1.284"
                      transform="translate(3.695 3.695)"
                      fill="#898b8e"
                    />
                    <g
                      id="Group_259"
                      data-name="Group 259"
                      transform="translate(2.776 2.78)"
                    >
                      <path
                        id="Path_246"
                        data-name="Path 246"
                        d="M337.54,308.629a.359.359,0,1,1,.358.361A.359.359,0,0,1,337.54,308.629Z"
                        transform="translate(-335.697 -308.271)"
                        fill="#363538"
                      />
                      <path
                        id="Path_247"
                        data-name="Path 247"
                        d="M337.54,327.518a.359.359,0,1,1,.358.361A.359.359,0,0,1,337.54,327.518Z"
                        transform="translate(-335.697 -323.473)"
                        fill="#363538"
                      />
                      <path
                        id="Path_248"
                        data-name="Path 248"
                        d="M330.966,311.65a.359.359,0,1,1,.508,0A.359.359,0,0,1,330.966,311.65Z"
                        transform="translate(-330.322 -310.498)"
                        fill="#363538"
                      />
                      <path
                        id="Path_249"
                        data-name="Path 249"
                        d="M344.322,325.006a.359.359,0,1,1,.508,0A.359.359,0,0,1,344.322,325.006Z"
                        transform="translate(-341.072 -321.247)"
                        fill="#363538"
                      />
                      <path
                        id="Path_250"
                        data-name="Path 250"
                        d="M328.453,318.434a.359.359,0,1,1,.361-.357A.359.359,0,0,1,328.453,318.434Z"
                        transform="translate(-328.096 -315.873)"
                        fill="#363538"
                      />
                      <path
                        id="Path_251"
                        data-name="Path 251"
                        d="M347.342,318.434a.359.359,0,1,1,.361-.357A.359.359,0,0,1,347.342,318.434Z"
                        transform="translate(-343.298 -315.873)"
                        fill="#363538"
                      />
                      <path
                        id="Path_252"
                        data-name="Path 252"
                        d="M331.474,325.009a.359.359,0,1,1,0-.508A.36.36,0,0,1,331.474,325.009Z"
                        transform="translate(-330.322 -321.247)"
                        fill="#363538"
                      />
                      <path
                        id="Path_253"
                        data-name="Path 253"
                        d="M344.831,311.652a.359.359,0,1,1,0-.508A.359.359,0,0,1,344.831,311.652Z"
                        transform="translate(-341.072 -310.498)"
                        fill="#363538"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <rect
        id="Rectangle_42"
        data-name="Rectangle 42"
        width="8.708"
        height="8.708"
        rx="4.354"
        transform="translate(73.488 167.917)"
        fill="#262227"
      />
      <rect
        id="Rectangle_43"
        data-name="Rectangle 43"
        width="27.532"
        height="8.708"
        rx="4.354"
        transform="translate(85.875 167.917)"
        fill="#262227"
      />
      <path
        id="Path_254"
        data-name="Path 254"
        d="M388.552,206.69a.11.11,0,0,1-.078-.188l2.485-2.485a.11.11,0,0,1,.156.155l-2.485,2.485A.11.11,0,0,1,388.552,206.69Z"
        transform="translate(-282.021 -34.999)"
        fill="#363538"
      />
      <path
        id="Path_255"
        data-name="Path 255"
        d="M393.394,220.739a.11.11,0,0,1-.078-.188l3.882-3.882a.11.11,0,1,1,.156.155l-3.882,3.882A.11.11,0,0,1,393.394,220.739Z"
        transform="translate(-285.918 -45.183)"
        fill="#363538"
      />
      <path
        id="Path_256"
        data-name="Path 256"
        d="M384.047,223.109a.11.11,0,0,1-.077-.189l2.513-2.445a.11.11,0,0,1,.153.158l-2.513,2.445A.11.11,0,0,1,384.047,223.109Z"
        transform="translate(-278.395 -48.247)"
        fill="#363538"
      />
      <path
        id="Path_257"
        data-name="Path 257"
        d="M306.847,206.69a.11.11,0,0,1-.078-.188l2.485-2.485a.11.11,0,0,1,.156.155l-2.485,2.485A.109.109,0,0,1,306.847,206.69Z"
        transform="translate(-216.262 -34.999)"
        fill="#363538"
      />
      <path
        id="Path_258"
        data-name="Path 258"
        d="M311.689,220.739a.11.11,0,0,1-.078-.188l3.882-3.882a.11.11,0,1,1,.156.155l-3.882,3.882A.11.11,0,0,1,311.689,220.739Z"
        transform="translate(-220.159 -45.183)"
        fill="#363538"
      />
      <path
        id="Path_259"
        data-name="Path 259"
        d="M302.342,223.109a.11.11,0,0,1-.077-.189l2.513-2.445a.11.11,0,1,1,.153.158l-2.513,2.445A.109.109,0,0,1,302.342,223.109Z"
        transform="translate(-212.637 -48.247)"
        fill="#363538"
      />
      <path
        id="Path_260"
        data-name="Path 260"
        d="M235.183,220.739a.11.11,0,0,1-.078-.188l3.882-3.882a.11.11,0,1,1,.156.155l-3.882,3.882A.11.11,0,0,1,235.183,220.739Z"
        transform="translate(-158.586 -45.183)"
        fill="#363538"
      />
      <path
        id="Path_261"
        data-name="Path 261"
        d="M225.836,223.109a.11.11,0,0,1-.077-.189l2.513-2.445a.11.11,0,0,1,.153.158l-2.513,2.445A.11.11,0,0,1,225.836,223.109Z"
        transform="translate(-151.063 -48.247)"
        fill="#363538"
      />
      <text
        id="EMERGENCY_AMBULANCE"
        data-name="EMERGENCY
    AMBULANCE"
        transform="translate(96.974 164.265)"
        fill="#006f23"
        font-size="1"
        font-family="Arial-BoldMT, Arial"
        font-weight="700"
      ><tspan
        x="-3.223"
        y="0"
      >EMERGENCY</tspan><tspan
        x="-3.222"
        y="1"
      >AMBULANCE</tspan></text>
    </g>
  </svg>
</template>
